import { Nullable } from '@/core/domain/type/types'

export default class BindServiceCreateCommand
{
    private release_guid: string;
    private release_id: number;
    private service_id: number;
    private service_version: string;
    private service_release_date: string;

    constructor(release_guid: string, release_id: number, service_id: number, service_version: string, service_release_date: string)
    {
        this.release_guid = release_guid;
        this.release_id = release_id;
        this.service_id = service_id;
        this.service_version = service_version;
        this.service_release_date = service_release_date;
    }

    getClassName(): string
    {
        return 'BindServiceCreateCommand';
    }

    getReleaseGuid(): string
    {
        return this.release_guid;
    }

    getReleaseId(): number
    {
        return this.release_id;
    }

    getServiceId(): number
    {
        return this.service_id;
    }

    getServiceVersion(): string
    {
        return this.service_version;
    }

    getServiceReleaseDate(): string
    {
        return this.service_release_date;
    }

}